import axios from 'axios';

import { httpAuthService } from './http.auth.service';
import { storage } from '@commons/storage';

import { path as pathCommons } from '@commons';
import { CONFIG_SESSION } from '@handler';

export const httpService = {
    post,
    postPlainText,
    postFile,
    put,
    get,
    getPdf,
    getPdfModal,
    getFile,
    getFileExcel,
    del,
    getApiManager,
    postApiManager,
    putApiManager,
    delApiManager,
    putAttachFiles,
    putDeleteFiles,
    getFileToDownload,
    getFilePresignedUrl
};

async function post(path, body) {
    return await req({ method: 'post', path: path, data: body });
}

async function postPlainText(path, body) {
    return await req({
        method: 'post',
        path: path,
        data: body,
        headers: {
            'Content-Type': 'text/plain',
        },
    });
}

async function postFile(path, body) {
    return await reqFile({ method: 'post', path: path, data: body });
}

async function put(path, body) {
    const response = await req({ method: 'put', path: path, data: body });
    return await response.data;
}

// return body only ok
async function get(path) {
    const response = await req({ method: 'get', path: path });
    return await response.data;
}

async function getFileExcel(path) {
    await reqExcel({ path: path });
}

async function getFile(path, filename) {
    await downloadFile({ path: path, filename: filename });
}

async function putAttachFiles(path, files) {
    await reqAttachFiles({ path: path }, files);
}

async function putDeleteFiles(path, filesIdList) {
    await reqDeleteAttachFiles({ path: path }, filesIdList);
}

async function getFileToDownload(path, fileName, folderPath) {
    return await reqFileToDownload({ path: path }, fileName, folderPath);
}

async function getFilePresignedUrl(path, fileName, folderPath) {
    return await reqFilePresignedUrl({ path: path }, fileName, folderPath);
}

async function getApiManager(paramsUrl, useAccessToken) {
    var headers = {};
    if (useAccessToken !== undefined && useAccessToken !== null && useAccessToken === true) {
        headers = {
            'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
        };
    }

    var response = await axios({
        url: paramsUrl,
        method: 'GET',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers,
    });
    return response.data;
}

async function postApiManager(paramsUrl, payload) {
    var headers = {
        'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
    };

    var response = await axios({
        url: paramsUrl,
        method: 'POST',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers,
        data: payload
    });
    return response.data;
}

async function delApiManager(paramsUrl) {
    var headers = {
        'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
    };

    var response = await axios({
        url: paramsUrl,
        method: 'delete',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers
    });
    return response.data;
}

async function putApiManager(paramsUrl, payload) {
    var headers = {
        'Authorization': 'Bearer ' + await storage.get(pathCommons.token)
    };

    var response = await axios({
        url: paramsUrl,
        method: 'PUT',
        baseURL: pathCommons.baseManager,
        timeout: 30000,
        headers: headers,
        data: payload
    });
    return response.data;
}

// get file
async function downloadFile({ path, filename }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    try {
        const response = await axios({
            method: 'GET',
            url: url,
            responseType: 'blob', // 'blob' é necessário para arquivos binários
        });

        // Cria uma URL temporária para o blob
        const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));

        // Cria um link temporário para forçar o download
        const tempLink = document.createElement('a');
        tempLink.href = fileURL;
        tempLink.setAttribute('download', filename); // Nome do arquivo
        tempLink.click(); // Dispara o download

        // Opcional: Revoga o objeto URL para liberar memória
        window.URL.revokeObjectURL(fileURL);
    } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
        throw error;
    }
}

// get pdf
async function getPdf(path) {
    await reqPdf({ path: path });
}

async function getPdfModal(path) {
    return await reqPdfModal({ path: path });
}

async function del(path) {
    return await req({ method: 'delete', path: path });
}

/////////////////////////////////////////////////////////////////////////////////
async function req({ method, path, data, headers = {} }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;
    const url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    // Adiciona os cabeçalhos personalizados (se existirem) na chamada do axios
    const response = await axios({
        method: method,
        url: url,
        data: data,
        headers: headers, // Inclui os cabeçalhos aqui
    });

    return response;
}

async function reqAttachFiles({ path }, files) {
    await httpAuthService.tokenByRefresh(path);

    const formData = new FormData();
    files.forEach((fileItem) => {
        formData.append('files', fileItem.file);
        formData.append('filesNames', fileItem.customName || fileItem.file.name);
    });

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;
    let url = path.args === undefined ? `${path.base}/${tenant}${path.path}` : `${path.base}/${tenant}${path.path}${path.args}`;

    var response = await axios({
        method: 'PUT',
        url: url,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

async function reqDeleteAttachFiles({ path }, filesIdList) {
    await httpAuthService.tokenByRefresh(path);

    const formData = new FormData();
    filesIdList.forEach(id => {
        formData.append('filesId', id);
    });

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;
    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    var response = await axios({
        method: 'PUT',
        url: url,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}

async function reqFileToDownload({ path }, fileName, folderPath) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;
    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    // Anexando parâmetros à URL
    url += `?fileName=${encodeURIComponent(fileName)}&folder=${encodeURIComponent(folderPath)}`;

    var response = await axios({
        method: 'GET',
        url: url,
        responseType: 'blob'
    });
    return response;
}

async function reqFilePresignedUrl({ path }, fileName, folderPath) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;
    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    // Anexando parâmetros à URL
    url += `?fileName=${encodeURIComponent(fileName)}&folder=${encodeURIComponent(folderPath)}`;

    var response = await axios({
        method: 'GET',
        url: url,
    });
    return response;
}

// req file
async function reqFile({ method, path, data }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    var response = await axios({
        method: method,
        url: url,
        headers: { "Content-Type": `multipart/form-data; boundary=${data._boundary}` },
        data: data
    });
    return response;
}

// abrir pdf
async function reqPdf({ path }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const report = new Blob([response.data],
            { type: 'application/pdf;base64' })
        const reportUrl = window.URL.createObjectURL(report)
        window.open(reportUrl, "_blank")
    });
}

async function reqPdfModal({ path }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;
    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    try {
        const response = await axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
        });

        // Criar o Blob do PDF
        const report = new Blob([response.data], { type: 'application/pdf' });

        // Gerar a URL do PDF
        const pdfUrl = window.URL.createObjectURL(report);

        return pdfUrl; 
    } catch (error) {
        throw error;
    }
}



//abrir excel
async function reqExcel({ path }) {
    await httpAuthService.tokenByRefresh(path);

    const tenant = (await JSON.parse(storage.get(CONFIG_SESSION))).schemaSelecionado;

    let url = path.args === undefined ? path.base + '/' + tenant + path.path : path.base + '/' + tenant + path.path + path.args;

    await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', 'planilha.xlsx');
        link.click();
        window.URL.revokeObjectURL(blobUrl);
    });
}
/////////////////////////////////////////////////////////////////////////////////
