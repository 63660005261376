
export const NEW_NFE_EMISSAO = 'NEW_NFE_EMISSAO'
export const NEW_NFE_EMISSAO_SUCCESS = 'NEW_NFE_EMISSAO_SUCCESS'

export const NEW_FILTER_NFE_EMISSAO = 'NEW_FILTER_NFE_EMISSAO'
export const NEW_FILTER_NFE_EMISSAO_PEDING = 'NEW_FILTER_NFE_EMISSAO_PEDING'
export const NEW_FILTER_NFE_EMISSAO_SUCCESS = 'NEW_FILTER_NFE_EMISSAO_SUCCESS'
export const NEW_FILTER_NFE_EMISSAO_FAILURE = 'NEW_FILTER_NFE_EMISSAO_FAILURE'

export const NEW_FILTER_NEXT_PAGE_NFE_EMISSAO = 'NEW_FILTER_NEXT_PAGE_NFE_EMISSAO'
export const NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_PENDING = 'NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_PENDING'
export const NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_SUCCESS = 'NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_SUCCESS'
export const NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_FAILURE = 'NEW_FILTER_NEXT_PAGE_NFE_EMISSAO_FAILURE'

export const GET_TOTAIS_NFE_EMISSAO_PENDING = "GET_TOTAIS_NFE_EMISSAO_PENDING";
export const GET_TOTAIS_NFE_EMISSAO_SUCCESS = "GET_TOTAIS_NFE_EMISSAO_SUCCESS";
export const GET_TOTAIS_NFE_EMISSAO_FAILURE = "GET_TOTAIS_NFE_EMISSAO_FAILURE";

export const CREATE_NFE_EMISSAO = 'CREATE_NFE_EMISSAO'
export const CREATE_NFE_EMISSAO_PENDING = 'CREATE_NFE_EMISSAO_PENDING'
export const CREATE_NFE_EMISSAO_SUCCESS = 'CREATE_NFE_EMISSAO_SUCCESS'
export const CREATE_NFE_EMISSAO_FAILURE = 'CREATE_NFE_EMISSAO_FAILURE'

export const FIND_NFE_EMISSAO = 'FIND_NFE_EMISSAO'
export const FIND_NFE_EMISSAO_PENDING = 'FIND_NFE_EMISSAO_PENDING'
export const FIND_NFE_EMISSAO_SUCCESS = 'FIND_NFE_EMISSAO_SUCCESS'
export const FIND_NFE_EMISSAO_FAILURE = 'FIND_NFE_EMISSAO_FAILURE'

export const DELETE_NFE_EMISSAO = 'DELETE_NFE_EMISSAO'
export const DELETE_NFE_EMISSAO_PENDING = 'DELETE_NFE_EMISSAO_PENDING'
export const DELETE_NFE_EMISSAO_SUCCESS = 'DELETE_NFE_EMISSAO_SUCCESS'
export const DELETE_NFE_EMISSAO_FAILURE = 'DELETE_NFE_EMISSAO_FAILURE'

export const EMITIR_NFE_EMISSAO = 'EMITIR_NFE_EMISSAO'
export const EMITIR_NFE_EMISSAO_PENDING = 'EMITIR_NFE_EMISSAO_PENDING'
export const EMITIR_NFE_EMISSAO_SUCCESS = 'EMITIR_NFE_EMISSAO_SUCCESS'
export const EMITIR_NFE_EMISSAO_FAILURE = 'EMITIR_NFE_EMISSAO_FAILURE'

export const CANCELAR_NFE_EMISSAO = 'CANCELAR_NFE_EMISSAO'
export const CANCELAR_NFE_EMISSAO_PENDING = 'CANCELAR_NFE_EMISSAO_PENDING'
export const CANCELAR_NFE_EMISSAO_SUCCESS = 'CANCELAR_NFE_EMISSAO_SUCCESS'
export const CANCELAR_NFE_EMISSAO_FAILURE = 'CANCELAR_NFE_EMISSAO_FAILURE'

export const CORRIGIR_CCE_NFE_EMISSAO = 'CORRIGIR_CCE_NFE_EMISSAO'
export const CORRIGIR_CCE_NFE_EMISSAO_PENDING = 'CORRIGIR_CCE_NFE_EMISSAO_PENDING'
export const CORRIGIR_CCE_NFE_EMISSAO_SUCCESS = 'CORRIGIR_CCE_NFE_EMISSAO_SUCCESS'
export const CORRIGIR_CCE_NFE_EMISSAO_FAILURE = 'CORRIGIR_CCE_NFE_EMISSAO_FAILURE'

export const DUPLICAR_NFE_EMISSAO = 'DUPLICAR_NFE_EMISSAO'
export const DUPLICAR_NFE_EMISSAO_PENDING = 'DUPLICAR_NFE_EMISSAO_PENDING'
export const DUPLICAR_NFE_EMISSAO_SUCCESS = 'DUPLICAR_NFE_EMISSAO_SUCCESS'
export const DUPLICAR_NFE_EMISSAO_FAILURE = 'DUPLICAR_NFE_EMISSAO_FAILURE'