import { takeLatest, put, call } from 'redux-saga/effects';
import { storage } from '@commons/storage';

import {
  CONFIG_SESSION,
  NEW_FILTER_NFE_EMISSAO,
  NEW_FILTER_NFE_EMISSAO_PEDING,
  NEW_FILTER_NFE_EMISSAO_SUCCESS,
  NEW_FILTER_NFE_EMISSAO_FAILURE,
  GET_TOTAIS_NFE_EMISSAO_FAILURE,
  GET_TOTAIS_NFE_EMISSAO_SUCCESS,
  GET_TOTAIS_NFE_EMISSAO_PENDING,
} from '@handler';
import { formatDateDDMMYYYY, formatDateYYYYMMDD } from '@components/common/format';
import { filterNFeEmissao, getTotaisNFeEmissao } from '../service';
import { CONST_FILTER_NFE_EMISSAO, CONST_ROWS_PER_PAGE } from '@commons/consts';

function* sagaNewFilterNFeEmissao(action) {
  try {
    yield put({ type: NEW_FILTER_NFE_EMISSAO_PEDING });

    const idFazenda = JSON.parse(storage.get(CONFIG_SESSION)).fazendaSelecionada.id;
    let datas = '';
    let dataInicial = '';
    let dataFinal = '';
    var date = new Date();
    let where = '';
    let newFilter = {
      status: 'TODAS',
      ambiente: 'PRODUCAO',
      tipoOperacao: null,
      emitente: null,
      destinatario: null,
      naturezaOperacao: null,
      dataInicial: '',
      dataFinal: '',
      numero: '',
      rows: CONST_ROWS_PER_PAGE,
      page: 0,
      where: ''
    };

    let filterSaved = sessionStorage.getItem(CONST_FILTER_NFE_EMISSAO) !== null ? JSON.parse(sessionStorage.getItem(CONST_FILTER_NFE_EMISSAO)) : null;

    if (filterSaved === null || filterSaved === undefined) {
      // seta data do mes na primeira vez que entra na pagina
      dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
      dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      where = where + '&ambiente=PRODUCAO';

      datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);

      newFilter = {
        ...newFilter,
        dataInicial,
        dataFinal,
      };

    } else {
      // se seta filtro manual
      if (action.newFilter) {
        // seta filtro indicado
        // typePeriodo = 0 -> periodo manual
        // typePeriodo = 1 -> hoje
        // typePeriodo = 2 -> esta semana
        // typePeriodo = 3 -> mes passado
        // typePeriodo = 4 -> este mes
        // typePeriodo = 5 -> proximo mes
        // typePeriodo = 6 -> este ano
        if (action.typePeriodo === undefined || action.typePeriodo === 0) {
          dataInicial = action.args.dataInicial;
          dataFinal = action.args.dataFinal;

          datas = formatDateDDMMYYYY(action.args.dataInicial) + '_' + formatDateDDMMYYYY(action.args.dataFinal);
        } else if (action.typePeriodo === 1) {
          dataInicial = formatDateYYYYMMDD(new Date(date));
          dataFinal = formatDateYYYYMMDD(new Date(date));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }
        else if (action.typePeriodo === 2) {
          const primeiro = date.getDate() - date.getDay();

          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), primeiro + 6));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 3) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() - 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 4) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth(), 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 5) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 2, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        } else if (action.typePeriodo === 6) {
          dataInicial = formatDateYYYYMMDD(new Date(date.getFullYear(), 0, 1));
          dataFinal = formatDateYYYYMMDD(new Date(date.getFullYear(), 12, 0));

          datas = formatDateDDMMYYYY(dataInicial) + '_' + formatDateDDMMYYYY(dataFinal);
        }

        if (action.args.status !== 'TODAS') {
          where = where + '&status=' + action.args.status;
        }
        if (action.args.emitente !== null && action.args.emitente !== undefined && action.args.emitente !== '') {
          where = where + '&emitente_emitente=' + action.args.emitente.id;
        }
        if (action.args.destinatario !== null && action.args.destinatario !== undefined && action.args.destinatario !== '') {
          where = where + '&destinatario_destinatario=' + action.args.destinatario.id;
        }
        if (action.args.naturezaOperacao !== null && action.args.naturezaOperacao !== undefined && action.args.naturezaOperacao !== '') {
          where = where + '&naturezaOperacao=' + action.args.naturezaOperacao.id;
        }
        if (action.args.numero !== null && action.args.numero !== undefined && action.args.numero !== '') {
          where = where + '&numero=' + action.args.numero;
        }
        if (action.args.tipoOperacao !== null && action.args.tipoOperacao !== undefined && action.args.tipoOperacao !== '') {
          where = where + '&tipoOperacao=' + action.args.tipoOperacao;
        }
        if (action.args.ambiente !== null && action.args.ambiente !== undefined && action.args.ambiente !== '') {
          where = where + '&ambiente=' + action.args.ambiente;
        } else {
          where = where + '&ambiente=PRODUCAO';
        }

        newFilter = {
          ...newFilter,
          ...action.args,
          dataInicial,
          dataFinal,
          where: where
        };

      } else {
        // busca filtro salvo
        datas = formatDateDDMMYYYY(filterSaved.dataInicial) + '_' + formatDateDDMMYYYY(filterSaved.dataFinal);

        if (filterSaved.status !== 'TODAS') {
          where = where + '&status=' + filterSaved.status;
        }
        if (filterSaved.emitente !== null && filterSaved.emitente !== undefined && filterSaved.emitente !== '') {
          where = where + '&emitente_emitente=' + filterSaved.emitente.id;
        }
        if (filterSaved.destinatario !== null && filterSaved.destinatario !== undefined && filterSaved.destinatario !== '') {
          where = where + '&destinatario_destinatario=' + filterSaved.destinatario.id;
        }
        if (filterSaved.naturezaOperacao !== null && filterSaved.naturezaOperacao !== undefined && filterSaved.naturezaOperacao !== '') {
          where = where + '&naturezaOperacao=' + filterSaved.naturezaOperacao.id;
        }
        if (filterSaved.numero !== null && filterSaved.numero !== undefined && filterSaved.numero !== '') {
          where = where + '&numero=' + filterSaved.numero;
        }
        if (filterSaved.tipoOperacao !== null && filterSaved.tipoOperacao !== undefined && filterSaved.tipoOperacao !== '') {
          where = where + '&tipoOperacao=' + filterSaved.tipoOperacao;
        }
        if (filterSaved.ambiente !== null && filterSaved.ambiente !== undefined && filterSaved.ambiente !== '') {
          where = where + '&ambiente=' + filterSaved.ambiente;
        } else {
          where = where + '&ambiente=PRODUCAO';
        }

        newFilter = {
          ...filterSaved,
          rows: CONST_ROWS_PER_PAGE,
          page: 0,
          where: where
        };
      }
    }

    // busca registros
    const list = yield call(filterNFeEmissao, datas, `&page=0&size=${CONST_ROWS_PER_PAGE}${where}`);

    sessionStorage.setItem(CONST_FILTER_NFE_EMISSAO, JSON.stringify(newFilter));

    yield put({
      type: NEW_FILTER_NFE_EMISSAO_SUCCESS, list: list, filter: newFilter
    });

    // busca totais
    yield put({ type: GET_TOTAIS_NFE_EMISSAO_PENDING });
    const totais = yield call(getTotaisNFeEmissao, datas, where);
    yield put({ type: GET_TOTAIS_NFE_EMISSAO_SUCCESS, totais });
  } catch (e) {

    yield put({ type: NEW_FILTER_NFE_EMISSAO_FAILURE })
    yield put({ type: GET_TOTAIS_NFE_EMISSAO_FAILURE });
  }
}

export default function* watchNewFilterNFeEmissao() {
  yield takeLatest(NEW_FILTER_NFE_EMISSAO, sagaNewFilterNFeEmissao)
}
